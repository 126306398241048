<template>
    <div ref="voltage-chart-container" class="voltage-chart-box">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'VoltageChart',
    methods: {
        getOption() {
            const option = {
                grid: {
                    top: 20,
                    left: 40,
                    bottom: 30,
                    right: 10
                },
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15, 16, 17, 18, 19, 20],
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    min: 3850,
                    name: '电池号',
                    nameLocation: 'start',
                    nameTextStyle: {
                        color: '#E3F0FF',
                        align: 'right'
                    }, 
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [{
                    data: [3860, 3860, 3865, 3860, 3861, 3860, 3870, 3866, 3869, 3867,3870,3862, 3860,3860, 3865, 3860,3864, 3860, 3864, 3860, ],
                    type: 'bar',
                    itemStyle: {
                        color: 'rgba(63, 227, 237, .4)'
                    },
                    barWidth: 8
                }
               ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['voltage-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less" scoped>
    .voltage-chart-box {
        height: 100%;
        width: 100%;
    }
</style>