<template>
    <div ref="income-breakdown-container" class="income-breakdown-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'IncomeBreakdownChart',
    data: () => ({
        chart: null
    }),
    methods: {
        getOption() {
            const option = {
                title: {
                    text: '收益分解',
                    left: 0,
                    bottom: 'center',
                    textStyle: {
                        color: '#3FE3ED',
                        fontSize: 12
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '75%',
                        data: [
                            {value: 90.11, name: '租金', itemStyle: { color: '#367385' }},
                            {value: 9.01, name: '梯次利用', itemStyle: { color: '#2c5f74' }},
                            {value: 0.88, name: '残值', itemStyle: { color: '#244760' }},
                        ],
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['income-breakdown-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .income-breakdown-container {
        height: 100%;
        width: 100%;
    }
</style>