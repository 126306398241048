<template>
    <div ref="life-cycle-chart-container" class="life-cycle-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'LifeCycleChart',
    data: () => ({
        chart: null
    }),
    methods: {
        getRightOption() {
            const option = {
                legend: {
                    show: false
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['40%', '60%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false
                        },
                        data: [
                            { 
                                value: 40.4, 
                                name: '放电',
                                itemStyle: {
                                    color: 'rgba(245, 132, 188, 1)'
                                },
                            },
                            { 
                                value: 22.1, 
                                name: '充电',
                                itemStyle: {
                                    color: 'rgba(130, 210, 237, 1)'
                                },
                            },
                            { 
                                value: 25.7, 
                                name: '静置',
                                itemStyle: {
                                    color: 'rgba(116, 109, 206, 1)'
                                },
                            },
                            { 
                                value: 0, 
                                name: '故障',
                                itemStyle: {
                                    color: 'rgba(226, 171, 98, 1)'
                                },
                            },
                            { 
                                value: 11.8, 
                                name: '闲置',
                                itemStyle: {
                                    color: 'rgba(28, 38, 89, 1)'
                                },
                            }
                        ]
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['life-cycle-chart-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getRightOption());
    }
}
</script>

<style lang="less">
    .life-cycle-chart-container {
        width: 100%;
        height: 100%;
    }
</style>