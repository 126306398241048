<template>
    <div ref="battery-use-chart-container" class="battery-use-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'BatteryUseChart',
    data: () => ({
        chart: null
    }),
    methods: {
        getOption() {
            const option = {
                title: {
                    text: '电池使用率',
                    show: true,
                    textStyle: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 400
                    },
                    left: 14
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    top: 30,
                    left: 20,
                    textStyle: {
                        color: '#00B5ED'
                    },
                    icon: 'rect',
                    itemWidth: 10,
                    itemHeight: 4,
                    padding: 3
                },
                series: [
                    {
                        type: 'pie',
                        center: ['80%', '50%'],
                        radius: ['40%', '60%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false
                        },
                        data: [
                            { 
                                value: 38.2, 
                                name: '放电',
                                itemStyle: {
                                    color: 'rgba(245, 132, 188, 1)'
                                },
                            },
                            { 
                                value: 20.8, 
                                name: '充电',
                                itemStyle: {
                                    color: 'rgba(130, 210, 237, 1)'
                                },
                            },
                            { 
                                value: 24.4, 
                                name: '静置',
                                itemStyle: {
                                    color: 'rgba(116, 109, 206, 1)'
                                },
                            },
                            { 
                                value: 0, 
                                name: '故障',
                                itemStyle: {
                                    color: 'rgba(226, 171, 98, 1)'
                                },
                            },
                            { 
                                value: 16.6, 
                                name: '闲置',
                                itemStyle: {
                                    color: 'rgba(28, 38, 89, 1)'
                                },
                            }
                        ]
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['battery-use-chart-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .battery-use-chart-container {
        width: 100%;
        height: 100%;
    }
</style>