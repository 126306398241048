<template>
    <div ref="battery-pack-radar-container" class="battery-pack-radar-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'BatteryPackRadar',
    data: () => ({
        chart: null
    }),
    methods: {
        getOption() {
            const option = {
                tooltip: {
                    trigger: 'item'
                },
                radar: {
                    shape: 'circle',
                    radius: '52%',
                    splitArea: {
                        areaStyle: {
                            color: ['rgba(32, 255, 250, 0)', 'rgba(32, 255, 250, 0)']
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['rgba(47, 81, 126, 1)']
                        }
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(47, 81, 126, 1)'
                        }
                    },
                    indicator: [
                        { name: '容量', max: 100, color: 'rgba(32, 255, 250, 1)'},
                        { name: '一致性', max: 100, color: 'rgba(32, 255, 250, 1)'},
                        { name: '安全性', max: 100, color: 'rgba(32, 255, 250, 1)'},
                        { name: '系统滥用', max: 100, color: 'rgba(32, 255, 250, 1)'},
                        { name: '阻抗', max: 100, color: 'rgba(32, 255, 250, 1)'},
                        { name: '电芯滥用', max: 100, color: 'rgba(32, 255, 250, 1)'}
                    ]
                },
                series: [{
                    name: '电池包',
                    type: 'radar',
                    itemStyle: {
                        color: 'rgba(32, 255, 250, 1)'
                    },
                    areaStyle: {
                        color: 'rgba(63, 227, 237, 0.2)'
                    },
                    label: {
                        show: false,
                        color: 'rgba(32, 255, 250, 1)'
                    },
                    data: [
                        {
                            value: [97, 99.1, 99.5, 98.5, 98.0, 99.2]
                        }
                    ],
                }]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['battery-pack-radar-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .battery-pack-radar-container {
        position: absolute;
        right: 20px;
        bottom: 0px;
        width: 220px;
        height: 220px;
    }
</style>