<template>
    <div 
        ref="dataCenterPackContainer"
        class="data-center-pack-container"
        :style="pageStyle"
    >
        <div v-if="loading" class="loading-container">
            <div class="loading-container">
                <div id="loading-center">
                    <div id="loading-center-absolute">
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                        <div class="object"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="logo-container">
            <img class="logo-img" :src="require('./assets/page-logo.png')"/>
        </div>
        <div class="page-name">
            电池系统大数据监控-单个电池包
        </div>
        <div class="search-bar">
            <span class="name">PackSN号：</span> 
            <el-input class="search-input" v-model="searchValue" placeholder="请输入SN号"/>
        </div>
        <div class="user-portrait">
            <TitlePanel>
                <UserPortrait :data="userPortraitData"/>
            </TitlePanel>
        </div>
        <div class="vehicle-protrait">
            <TitlePanel :title="'车辆画像'">
                <VehiclePortraitData :data="vehiclePortraitData"/>
            </TitlePanel>
        </div>
        <div class="life-cycle-container">
            <TitlePanel :title="'生命周期价值数据'">
                <div class="life-top">
                    <div class="life-left-info-container">
                        <div class="life-left-top-table">
                            <div class="header">
                                <div class="header-item"></div>
                                <div class="header-item">近一个月</div>
                                <div class="header-item">生命周期</div>
                            </div>
                            <div class="table-content">
                                <div class="table-scorll">
                                    <div class="table-item" v-for="(item, index) in lifeLeftTableData" :key="index">
                                        <div class="table-column-item" style="paddingLeft: 20px;">{{ item.name }}</div>
                                        <div class="table-column-item">{{ item.mounth }}</div>
                                        <div class="table-column-item">{{ item.lifeCycle }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="life-left-bottom-chart">
                            <div class="left-chart">
                                <BatteryUsageChart />
                            </div>
                            <div class="right-chart">
                                <LifeCycleChart />
                            </div>
                        </div> 
                    </div>
                    <div class="life-right-info-container">
                        <div class="header">
                            <div class="header-item"></div>
                            <div class="header-item">近一个月</div>
                            <div class="header-item">生命周期</div>
                        </div>
                        <div class="table-content">
                            <div class="table-item" v-for="(item, index) in lifeRightTableData" :key="index">
                                <div class="table-column-item" style="paddingLeft: 20px;">{{ item.name }}</div>
                                <div class="table-column-item">{{ item.mounth }}</div>
                                <div class="table-column-item">{{ item.lifeCycle }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="life-bottom">
                    <div class="left-table-container">
                        <Tabs>
                            <template v-slot:now>
                                <FaultList :data="[]"/>
                            </template>
                            <template v-slot:history>
                                <FaultList :data="historyFaultList"/>
                            </template>
                        </Tabs>
                    </div>
                    <div class="right-pipe-container">
                        <div class="top-pipe-container"> 
                            <CostDivisionChart />
                        </div> 
                        <div class="bottom-pipe-container">
                            <IncomeBreakdownChart />
                        </div> 
                    </div>
                </div> 
            </TitlePanel>
        </div>
        <div class="voltage-chart-container">
            <ChartPanel :title="'电压'">
                <VoltageChart />
            </ChartPanel>
        </div>
        <div class="temperature-chart">
            <ChartPanel :title="'温度'">
                <TemperatureChart />
            </ChartPanel>
        </div>
        <div class="soc-chart">
            <ChartPanel :title="'SOC'">
                <SocChart />
            </ChartPanel>
        </div>
        <div class="soh-chart">
            <ChartPanel :title="'SOH'">
                <SohChart />
            </ChartPanel>
        </div>
        <div class="internal-resistance-chart">
            <ChartPanel :title="'内阻'">
                <InternalResistanceChart />
            </ChartPanel>
        </div>
        <div class="self-discharge-chart">
            <ChartPanel :title="'自放电'">
                <SelfDischargeChart />
            </ChartPanel>
        </div>
        <div class="battery-pack-data">
            <BatteryPackData />
        </div> 
        <div class="life-cycle-system">
            <TitlePanel :title="'生命周期价值数据-系统对比'">
                <div class="left-table-container">
                    <div class="header">
                        <div class="header-item"></div> 
                        <div class="header-item">BMS</div> 
                        <div class="header-item">云端</div> 
                    </div>
                    <div class="content">
                        <div class="life-cycle-data-item" v-for="(item, index) in lifeSystemData" :key="index">
                            <div class="data-item">{{ item.name }}</div>
                            <div class="data-item">{{ item.bms }}</div>
                            <div class="data-item">{{ item.yun }}</div>
                        </div>
                    </div>
                </div>
                <div class="right-chart">
                    <div class="header">
                        <div class="header-item">
                            <el-select v-model="soSelectValue" class="so-change-select" @change="soSelectChange">
                                <el-option :value="0" :label="'SOC对比图'"></el-option>
                                <el-option :value="1" :label="'SOH对比图'"></el-option>
                            </el-select>
                        </div> 
                    </div>
                    <div class="chart-container">
                        <LifeSysChartSoc v-if="soSelectValue === 0"/>
                        <LifeSysChartSoh v-if="soSelectValue === 1"/>
                    </div>
                </div>
            </TitlePanel>
        </div>
        <div class="battery-pack-list">
            <div class="fisrt-pack-row"> 
                <PackItemUnit class="pack-item-one" :data="['29', '3860', '0.4', '0.12', '70', '100']"/>
                <PackItemUnit class="pack-item-one" :data="['29', '3860', '0.3', '0.11', '70', '99']"/>
                <PackItemUnit class="pack-item-one" :data="['29', '3865', '0.4', '0.12', '71', '99']"/>
                <PackItemUnit class="pack-item-one" :data="['29', '3860', '0.4', '0.12', '70', '98']"/>
            </div>
            <div class="sorll-pack-content-item">
                <PackItem class="pack-item-one" :data="['29', '3861', '0.6', '0.06', '70', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3860', '0.4', '0.07', '70', '98']"/>
                <PackItem class="pack-item-one" :data="['30', '3870', '0.5', '0.12', '72', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3866', '0.4', '0.10', '71', '100']"/>
            </div>
            <div class="sorll-pack-content-item">
                <PackItem class="pack-item-one" :data="['29', '3866', '0.6', '0.12', '71', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3869', '0.4', '0.11', '72', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3867', '0.5', '0.11', '72', '98']"/>
                <PackItem class="pack-item-one" :data="['28', '3870', '0.4', '0.12', '72', '99']"/>
            </div>
            <div class="sorll-pack-content-item">
                <PackItem class="pack-item-one" :data="['29', '3870', '0.5', '0.08', '72', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3862', '0.4', '0.08', '70', '100']"/>
                <PackItem class="pack-item-one" :data="['28', '3860', '0.5', '0.11', '70', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3860', '0.4', '0.12', '70', '99']"/>
            </div>
            <div class="sorll-pack-content-item">
                <PackItem class="pack-item-one" :data="['30', '3865', '0.7', '0.10', '71', '100']"/>
                <PackItem class="pack-item-one" :data="['29', '3860', '0.7', '0.12', '70', '98']"/>
                <PackItem class="pack-item-one" :data="['29', '3864', '0.5', '0.08', '71', '99']"/>
                <PackItem class="pack-item-one" :data="['29', '3860', '0.4', '0.12', '70', '99']"/>
            </div>
        </div>
    </div>
</template>

<script>
import './Index.less';
import TitlePanel from './components/TitlePanel.vue';
import UserPortrait from './components/UserPortrait.vue';
import VehiclePortraitData from './components/VehiclePortrait.vue';
import ChartPanel from './components/ChartPanel.vue';
import VoltageChart from './components/VoltageChart.vue';
import TemperatureChart from './components/TemperatureChart.vue';
import SocChart from './components/SocChart.vue';
import SohChart from './components/SohChart.vue';
import InternalResistanceChart from './components/InternalResistanceChart.vue';
import SelfDischargeChart from './components/SelfDischargeChart.vue';
import BatteryUsageChart from './components/BatteryUseChart.vue';
import LifeCycleChart from './components/LifeCycleChart.vue';
import Tabs from './components/Tabs.vue';
import FaultList from './components/FaultList.vue';
import BatteryPackData from './components/BatteryPackData.vue'
import LifeSysChartSoc from './components/LifeSysChartSoc.vue';
import LifeSysChartSoh from './components/LifeSysChartSoh.vue';
import PackItem from './components/PackItem.vue';
import PackItemUnit from './components/PackItemUnit.vue';
import CostDivisionChart from './components/CostDivisionChart.vue';
import IncomeBreakdownChart from './components/IncomeBreakdownChart.vue';

export default {
    name: 'datCenterPack',
    components: {
        TitlePanel,
        UserPortrait,
        VehiclePortraitData,
        ChartPanel,
        VoltageChart,
        TemperatureChart,
        SocChart,
        SohChart,
        InternalResistanceChart,
        SelfDischargeChart,
        BatteryUsageChart,
        LifeCycleChart,
        Tabs,
        FaultList,
        BatteryPackData,
        LifeSysChartSoc,
        LifeSysChartSoh,
        PackItem,
        PackItemUnit,
        CostDivisionChart,
        IncomeBreakdownChart
    },
    data: () => {
        return {
            pageStyle: {
                transformOrigin: 'left top 0px'
            },
            pageWidth: 1920,
            pageHigth: 1080,
            searchValue: '',
            userPortraitData: [
                {
                    id: 1,
                    userName: '当前用户',
                    usageTime: '5天16h',
                    cycleTime: '76.2cycle',
                    drivingStyle: '激进',
                    cumulativeDuration: '95天16h',
                    jobType: '外卖专送',
                    forecastDemand: 6060,
                    selfCycleTime: '4.5cycle',
                    forecastDemandMile: '150km'
                },
                {
                    id: 2,
                    userName: '历史用户1',
                    usageTime: '90天',
                    cycleTime: '71cycle',
                    drivingStyle: '柔和',
                    cumulativeDuration: '90天',
                    jobType: '站点专送',
                    forecastDemand: 6060,
                    selfCycleTime: '71cycle',
                    forecastDemandMile: '120km'
                },
                {
                    id: 3,
                    userName: '历史用户2',
                    usageTime: '82天',
                    cycleTime: 32,
                    drivingStyle: '激进',
                    cumulativeDuration: '116天20h',
                    jobType: '外卖专送',
                    forecastDemand: 7255,
                    selfCycleTime: '65.8cycle',
                    forecastDemandMile: '140km'
                },
                {
                    id: 4,
                    userName: '历史用户3',
                    usageTime: '57天',
                    cycleTime: '74.4cycle',
                    drivingStyle: '激进',
                    cumulativeDuration: '92天10h',
                    jobType: '众包近单',
                    forecastDemand: '双包6040',
                    selfCycleTime: '45.6cycle',
                    forecastDemandMile: '150km'
                },
            ],
            vehiclePortraitData: [
                {
                    id: 1,
                    vehicleNumber: '当前车辆',
                    charger: '60V10A',
                    voltage: '72V',
                    range: '8%',
                    current: '9A',
                    mark: '无',
                    sop: '无',
                    currentFluctuation: '1A',
                    communication: '无',
                    vehicleMark: '无',
                    voltageFluctuation: '0.3V',
                    powerConsumption: '2.88kwh/100km'
                },
                {
                    id: 2,
                    vehicleNumber: '历史车辆1',
                    charger: '60V10A',
                    voltage: '72V',
                    range: '0%',
                    current: '9A',
                    mark: '无',
                    sop: '无',
                    currentFluctuation: '1A',
                    communication: '无',
                    vehicleMark: '能耗异常',
                    voltageFluctuation: '0.3V',
                    powerConsumption: '3.52kwh/100km'
                },
                {
                    id: 3,
                    vehicleNumber: '历史车辆2',
                    charger: '72V10A',
                    voltage: '84V',
                    range: '7%',
                    current: '9A',
                    mark: '无',
                    sop: '无',
                    currentFluctuation: '1A',
                    communication: '无',
                    vehicleMark: '无',
                    voltageFluctuation: '0.3V',
                    powerConsumption: '2.92kwh/100km'
                },
                {
                    id: 4,
                    vehicleNumber: '历史车辆3',
                    charger: '60V5A',
                    voltage: '72V',
                    range: '7%',
                    current: '4.5A',
                    mark: '无',
                    sop: '无',
                    currentFluctuation: '0.6A',
                    communication: '无',
                    vehicleMark: '无',
                    voltageFluctuation: '0.3V',
                    powerConsumption: '3.02kwh/100km'
                }
            ],
            lifeLeftTableData: [
                {
                    name: '价值收益',
                    mounth: 340,
                    lifeCycle: 4080
                },
                {
                    name: '服役时间',
                    mounth:  '25天3h',
                    lifeCycle: '322天'
                },
                {
                    name: '进化次数',
                    mounth: 0,
                    lifeCycle: 2
                },
                {
                    name: '循环次数',
                    mounth: '20.4cycle',
                    lifeCycle: '257.8cycle'
                },
            ],
            lifeRightTableData: [
                {
                    name: '故障总数',
                    mounth: 0,
                    lifeCycle: 2
                },
                {
                    name: '严重故障数',
                    mounth: 0,
                    lifeCycle: 0
                },
                {
                    name: '维修耗时',
                    mounth: 0,
                    lifeCycle: '53min'
                },
                {
                    name: '数据量',
                    mounth: '215M',
                    lifeCycle: '2G 606M'
                },
                {
                    name: '减少碳排量',
                    mounth: '58kg',
                    lifeCycle: '728kg'
                },
            ],
            lifeSystemData: [
                { name: 'SOC', bms: '65%', yun: '71%' },
                { name: 'SOH', bms: '92%', yun: '99%' },
                { name: 'SOP', bms: '30A', yun: '28A' },
                { name: '持续里程', bms: '82km', yun: '88km' },
                { name: '报警状态', bms: '无', yun: '无' }
            ],
            historyFaultList: [
                { happenTime: '2020-08-20', faultName: '接插件温度高', level: '3', unlockTime: '2020-08-20' },
                { happenTime: '2020-12-05', faultName: '电池电量低', level: '1', unlockTime: '2020-12-05' },
            ],
            soSelectValue: 0,
            loading: true
        }
    },
    methods: {
        pageScale() {
            const { clientWidth, clientHeight } = this.$refs.dataCenterPackContainer;
            const { pageWidth, pageHigth } = this;
            const xScale = clientWidth / pageWidth;
            const yScale = clientHeight / pageHigth;
            const pageStyle = {
                ...this.pageStyle,
                width: `${pageWidth}px`,
                height: `${pageHigth}px`,
                transform: `scale(${xScale}, ${yScale})`
            };
            this.pageStyle = pageStyle;
        },
        soSelectChange(value) {
            this.soSelectValue = value;
        }
    },
    mounted() {
        this.pageScale();
        document.onreadystatechange = () => {
            if (document.readyState === 'complete') {
                this.loading = false;
            }
        };
    }
}
</script>