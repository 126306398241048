<template>
    <div class="tabs-container">
        <div class="tabs-change-container">
            <div :class="{'tabs-item': true, 'tabs-item-select': selectIndex === 0}" @click="changeTab(0)">当前</div>
            <div :class="{'tabs-item': true, 'tabs-item-select': selectIndex === 1}" @click="changeTab(1)">历史</div>
        </div>
        <div class="tabs-content">
            <slot v-if="selectIndex === 0" name="now"/>
            <slot v-if="selectIndex === 1" name="history"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    data: () => ({
        selectIndex: 0
    }),
    methods: {
        changeTab(index) {
            this.selectIndex = index;
        }
    }
}
</script>

<style lang="less">
    .tabs-container {
        width: 100%;
        height: 100%;
        .tabs-change-container {
            box-sizing: border-box;
            display: flex;
            height: 30px;
            width: 100%;
            padding: 0 8px;
            .tabs-item-select {
                background: rgba(64, 155, 255, 0.1);
                border: 1px solid #2F517E;
                border-bottom: none;
                font-weight: bold !important;
                color: #409BFF !important;
            }
            .tabs-item {
                width: 60px;
                height: 30px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
        }
        .tabs-content {
            height: calc(100% - 30px);
        }
    }
</style>