<template>
    <div class="battery-pack-data-container">
        <div class="left-data">
            <div class="title">电池包数据</div>
            <div class="content">
                <div 
                    :class="{'pack-data-item': true, 'pack-data-item-last': index === 11 || index === 12 || index === 13}" 
                    v-for="(item, index) in packData" 
                    :key="index"
                >
                    <span>{{ item.name }}：</span>
                    <span>{{ item.value }}</span>
                </div>
            </div>
        </div>
        <div class="right-data">
            <div class="title">当前排名</div>
            <span class="rank-info" style="display: block;">2020-08批次<span class="rank-number">13</span> / 88</span>
            <span class="rank-info" style="display: block;"> 北京区域<span class="rank-number">83</span> / 1859</span>
        </div>
        <BatteryPackRadar />
    </div>
</template>

<script>
import BatteryPackRadar from './BatteryPackRadar.vue';

export default {
    name: 'BatteryPackData',
    components: {
        BatteryPackRadar
    },
    props: {
        packData: {
            type: Array,
            default: () => [
                { name: '流转状态', value: '租赁使用中' },
                { name: '工作状态', value: '静置' },
                { name: 'SOC', value: '71%' },
                { name: 'SOH', value: '99%' },
                { name: '电压', value: '77.3V' },
                { name: '电流', value: '0A' },
                { name: '绝缘值', value: '5460kOhm' },
                { name: '高压连接', value: '正常' },
                { name: '高压互锁', value: '正常' },
                { name: '内部连接', value: '正常' },
                { name: '电气系统寿命', value: '' },
                { name: '当前位置', value: '113.2086, 24.3325' },
                { name: '剩余寿命', value: '3年107天' },
                { name: '热失控概率', value: '0.0000001次/万公里' }
            ]
        }
    }
}
</script>

<style lang="less">
    .battery-pack-data-container {
        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 20px;
        .left-data {
            width: 65%;
            height: 100%;
            .title {
                height: 16px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
            .content {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                box-sizing: border-box;
                height: calc(100% - 16px);
                padding: 22px 0px 0px 25px;
                .pack-data-item {
                    width: 45%;
                    height: 13px;
                    margin-bottom: 8px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }
                .pack-data-item-last {
                    width: 55%;
                }
            }
        }
        .right-data {
            width: 35%;
            height: 100%;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            .title {
                height: 16px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #FFFFFF;
            }
            .rank-info {
                margin-top: 10px;
                margin-left: 20px;
            }
            .rank-number {
                margin-left: 10px;
                color: rgba(32, 255, 250, 1);
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
</style>