<template>
    <div class="pack-item-content">
        <div class="pack-item-unit">
            <div class="unit-item">
                <div class="unit-name">温度</div>
                <div class="unit-value">(度)</div>
            </div>
            <div class="unit-item">
                <div class="unit-name">电压</div>
                <div class="unit-value">(mv)</div>
            </div>
            <div class="unit-item">
                <div class="unit-name">内阻</div>
                <div class="unit-value">(mΩ)</div>
            </div>
            <div class="unit-item">
                <div class="unit-name">自放电</div>
                <div class="unit-value">(mA)</div>
            </div>
            <div class="unit-item">
                <div class="unit-name">SOC</div>
                <div class="unit-value">%</div>
            </div>
            <div class="unit-item">
                <div class="unit-name">SOH</div>
                <div class="unit-value">%</div>
            </div>
        </div>
        <div class="pack-item-data" v-for="(item, index) in data" :key="index">
            {{ item }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [60, 3860, 0.4, 0.12, 70, 99]
        }
    }
}
</script>

<style lang="less">
        .pack-item-content {
            position: relative;
            box-sizing: border-box;
            display: flex;
            width: 280px;
            height: 48px;
            border: 3px solid #3A7EA2;
            border-radius: 8px;
            cursor: pointer;
            .pack-item-data {
                flex: 1;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #20FFFA;
                line-height: 42px;
                text-align: center;
            }
            .pack-item-unit {
                position: absolute;
                top: -34px;
                display: flex;
                height: 24px;
                width: 280px;
                .unit-item {
                    flex: 1;
                    .unit-name, .unit-value {
                        height: 12px;
                        line-height: 12px;
                        text-align: center;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #20FFFA;
                        text-align: center;
                    }
                }
            }
        }
</style>