<template>
    <div ref="life-sys-chart-container" class="life-sys-chart-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'LifeSysChart',
    methods: {
        getOption() {
            const option = {
                legend: {
                    top: 10,
                    data: ['BMS', '云端'],
                    textStyle: {
                        color: '#01AAF2',
                        fontSize: 10
                    },
                    align: 'left',
                    itemWidth: 25,
                    orient: 'horizontal',
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    top: 40,
                    left: 40,
                    bottom: 20,
                    right: 20
                },
                xAxis: {
                    type: 'category',
                    data: [
                        '2021/7/12 0:00',
                        '2021/7/12 1:00',
                        '2021/7/12 2:00',
                        '2021/7/12 3:00',
                        '2021/7/12 4:00',
                        '2021/7/12 5:00',
                        '2021/7/12 6:00',
                        '2021/7/12 7:00',
                        '2021/7/12 8:00',
                        '2021/7/12 9:00',
                        '2021/7/12 10:00',
                        '2021/7/12 11:00',
                        '2021/7/12 12:00',
                        '2021/7/12 13:00',
                        '2021/7/12 14:00',
                        '2021/7/12 15:00',
                        '2021/7/12 16:00',
                        '2021/7/12 17:00',
                        '2021/7/12 18:00',
                        '2021/7/12 19:00',
                        '2021/7/12 20:00',
                        '2021/7/12 21:00',
                        '2021/7/12 22:00',
                        '2021/7/12 23:00'
                    ],
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: '#21abfa',
                        fontSize: 12,
                        fontWeight: 700,
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [
                    {
                        name: 'BMS',
                        data: [32.46, 51.06, 69.66, 88.26, 100, 100,100, 100, 100,100, 99.8, 93.8, 86.3, 74.3, 67, 66.09999986, 66.09999968, 63.90000892, 57.90003441, 40.2, 28.2, 16.2, 11.4],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(45, 146, 255, 1)',
                            width: 3
                        }
                    },
                    {
                        name: '云端',
                        data: [38.5, 56.5, 74.5, 92.5, 100, 100,100, 100, 100, 100, 99.84, 95.04, 88.94, 78.14, 71.57, 70.76, 71.3, 69.32, 58.52, 46.85, 34.85, 22.85, 18.05],
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: 'rgba(32, 255, 250, 1)',
                            width: 3
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['life-sys-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .life-sys-chart-container {
        width: 100%;
        height: 100%;
    }
</style>