<template>
  <div class="pack-item-container">
      <div class="pack-item-data" v-for="(item, index) in data" :key="index">
          {{ item }}
      </div>
  </div>
</template>

<script>
export default {
    name: 'PackItem',
    props: {
        data: {
            type: Array,
            default: () => [60, 3860, 0.4, 0.12, 70, 99]
        }
    }
}
</script>

<style lang="less">
    .pack-item-container {
        box-sizing: border-box;
        display: flex;
        width: 280px;
        height: 48px;
        border: 3px solid #3A7EA2;
        border-radius: 8px;
        cursor: pointer;
        .pack-item-data {
            flex: 1;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #20FFFA;
            line-height: 42px;
            text-align: center;
        }
    }
</style>