<template>
    <div class="chart-panel-container">
        <div class="title">
            {{ title }}
            <el-input class="search-input" v-model="value"/>
            <img class="search-icon" :src="require('../assets/search.png')"/>
        </div>
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChartPanel',
    props: {
        title: {
            type: String,
            default: '电压'
        }
    },
    data: () => {
        return {
            value: ''
        }
    }
}
</script>

<style lang="less">
    .chart-panel-container {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 8px 14px;
        .title {
            position: relative;
            height: 24px;
            line-height: 24px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3FE3ED;
            .search-input {
                float: right;
                width: 80px;
                height: 24px;
                .el-input__inner {
                    height: 24px;
                    background: rgba(63, 227, 237, .1);
                    border-radius: 4px;
                    color: #fff;
                    border: none;
                }
            }
            .search-icon {
                position: absolute;
                top: 4px;
                right: 5px;
            }
        }
        .content {
            height: calc(100% - 24px);
        }
    }
</style>