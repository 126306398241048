<template>
    <div ref="cost-division-container" class="cost-division-container">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'CostDivisionChart',
    data: () => ({
        chart: null
    }),
    methods: {
        getOption() {
            const option = {
                title: {
                    text: '成本分解',
                    right: 0,
                    bottom: 'center',
                    textStyle: {
                        color: '#3FE3ED',
                        fontSize: 12
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        type: 'pie',
                        radius: '75%',
                        data: [
                            {value: 47.32, name: '材料'},
                            {value: 0.95, name: '物流'},
                            {value: 51.10, name: '分销'},
                            {value: 0.16, name: '运维'},
                            {value: 0.16, name: '仓库'},
                            {value: 0.32, name: '其他'},
                        ],
                        label: {
                            show: false,
                            position: 'center'
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['cost-division-container'];
        const chart = echarts.init(chartDom);
        this.chart = chart;
        this.chart.setOption(this.getOption());
    }
}
</script>

<style lang="less">
    .cost-division-container {
        height: 100%;
        width: 100%;
    }
</style>