<template>
    <div ref="internal-res-chart-container" class="internal-res-chart-box">
    </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'InternalResistanceChart',
    methods: {
        getOption() {
            const option = {
                grid: {
                    top: 20,
                    left: 40,
                    bottom: 30,
                    right: 10
                },
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,15, 16, 17, 18, 19, 20],
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    },  
                    axisLine: {
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '电池号',
                    nameLocation: 'start',
                    min: 0.3,
                    nameTextStyle: {
                        color: '#E3F0FF',
                        align: 'right'
                    }, 
                    axisLabel: {
                        color: '#E3F0FF',
                        fontSize: 12,
                        fontWeight: 700
                    }, 
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#0C89AD',
                            opacity: 0.3
                        }
                    },
                    splitLine: {
                        lineStyle: {
                            color: ['#094B85'],
                            opacity: 0.3
                        }
                    }
                },
                series: [{
                    name: '运行',
                    data: [0.4, 0.3, 0.4, 0.4, 0.6, 0.4, 0.5,0.4, 0.6, 0.4,0.5, 0.4, 0.5, 0.4, 0.5, 0.4, 0.7,0.7, 0.5, 0.4 ],
                    type: 'bar',
                    itemStyle: {
                        color: 'rgba(226, 171, 98, 1)'
                    },
                    barWidth: 8
                }
               ]
            };
            return option;
        }
    },
    mounted() {
        const chartDom = this.$refs['internal-res-chart-container'];
        const myChart = echarts.init(chartDom);
        myChart.setOption(this.getOption());
    }
}
</script>

<style lang="less" scoped>
    .internal-res-chart-box {
        height: 100%;
        width: 100%;
    }
</style>