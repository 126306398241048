<template>
    <div class="fault-list-container">
        <div class="list-title">故障列表</div>
        <div class="list-column-container">
            <div class="column-item">发送时间</div>
            <div class="column-item">故障名称</div>
            <div class="column-item">故障等级</div>
            <div class="column-item">解除时间</div>
        </div>
        <div class="list-content">
            <div class="list-scroll">
                <div class="list-inner">
                    <div class="data-item" v-for="(item, index) in data" :key="index">
                        <div class="column-item" :title="item.happenTime">{{ item.happenTime }}</div>
                        <div class="column-item">{{ item.faultName }}</div>
                        <div class="column-item">{{ item.level }}</div>
                        <div class="column-item" :title="item.unlockTime">{{ item.unlockTime }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FaultList',
    props: {
        data: {
            type: Array,
            default: () => [
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' },
                { happenTime: '2021-09-21 21:32:32', faultName: 'xxxxx', level: '3级', unlockTime: '2021-09-21 21:32:32' }
            ]
        }
    }
}
</script>

<style lang="less">
    .fault-list-container {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 0 8px;
        .list-title {
            height: 30px;
            border: 1px solid #2F517E;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #409BFF;
            text-align: center;
            line-height: 30px;
        }
        .list-column-container {
            display: flex;
            height: 30px;
            border: 1px solid #2F517E;
            border-top: none;
            border-right: none;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #E3F0FF;
            text-align: center;
            line-height: 30px;
            .column-item {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex: 1;
                border-right: 1px solid #2F517E;
            }
        }
        .list-content {
            overflow: hidden;
            width: 100%;
            height: calc(100% - 70px);
            .list-scroll {
                overflow-y: scroll;
                width: 410px;
                height: 100%;
                .list-inner {
                    width: 364px;
                    height: 100%;
                    .data-item {
                        display: flex;
                        height: 30px;
                        border: 1px solid #2F517E;
                        border-top: none;
                        border-right: none;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #E3F0FF;
                        text-align: center;
                        line-height: 30px;
                        .column-item {
                            flex: 1;
                            border-right: 1px solid #2F517E;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
</style>