<template>
    <div class="user-portrait-container">
        <div class="slider-bar-container">
            <div 
                class="left-one-container"
                @click="preOne"
            >
                <div class="left-arrow"></div>
            </div> 
            <div 
                v-for="(item, index) in renderData"
                :class="{'bar-item': true, 'bar-item-select': item.id === selectId}"
                :key="index"
                :title="item.userName"
                @click="changeSelect(item.id)"
            >
                {{ item.userName }}
            </div>
             <div 
                class="right-one-container"
                @click="nextOne"
            >
                <div class="right-arrow"></div>
            </div> 
        </div>
        <div class="user-portrait-detail">
            <div class="detail-item">
                <span class="name">本电池使用时长：</span>
                <span class="value">{{ selectItem.usageTime }}</span>
            </div>
            <div class="detail-item">
                <span class="name">累计使用循环：</span>
                <span class="value">{{ selectItem.cycleTime }}</span>
            </div>
            <div class="detail-item">
                <span class="name">驾驶风格：</span>
                <span class="value">{{ selectItem.drivingStyle }}</span>
            </div>
            <div class="detail-item">
                <span class="name">累计使用时长：</span>
                <span class="value">{{ selectItem.cumulativeDuration }}</span>
            </div>
            <div class="detail-item">
                <span class="name">工作类型：</span>
                <span class="value">{{ selectItem.jobType }}</span>
            </div>
            <div class="detail-item">
                <span class="name">预计电池需求：</span>
                <span class="value">{{ selectItem.forecastDemand }}</span>
            </div>
            <div class="detail-item">
                <span class="name">本电池使用循环：</span>
                <span class="value">{{ selectItem.selfCycleTime }}</span>
            </div>
            <div class="detail-item">
                <span class="name">预计里程需求：</span>
                <span class="value">{{ selectItem.forecastDemandMile }}</span>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'UserPortrait',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data: () => {
        return {
            nowIndex: 0,
            selectId: null
        }
    },
    computed: {
        renderData() {
            return this.data.slice(this.nowIndex, this.nowIndex + 4);
        },
        selectItem() {
            return this.data.find(item => item.id === this.selectId) ? this.data.find(item => item.id === this.selectId) : {};
        }
    },
    methods: {
        changeSelect(id) {
            this.selectId = id;
        },
        preOne() {
            if (this.nowIndex === 0) {
                return;
            }
            this.nowIndex--;
        },
        nextOne() {
            if (this.nowIndex >= this.data.length - 4) {
                return;
            }
            this.nowIndex ++;
        }
    },
    mounted() {
        this.selectId = this.$props.data[0].id;
    }
}
</script>

<style lang="less" scoped>
    .user-portrait-container {
        height: 100%;
        width: 100%;
        .slider-bar-container {
            display: flex;
            height: 30px;
            padding: 0 23px;
            .left-one-container {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin-right: 5px;
                border: 1px solid #2F517E;
                cursor: pointer;
                .left-arrow {
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid rgba(227, 240, 255, 1);
                    border-left: 1px solid rgba(227, 240, 255, 1);
                    transform: rotate(-45deg)
                }
            }
            .bar-item {
                box-sizing: border-box;
                width: 76px;
                height: 30px;
                border: 1px solid #2F517E;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF;
                line-height: 30px;
                text-align: center;
                cursor: pointer;
            }
            .bar-item-select {
                background: #338CDE;
                font-weight: bold;
            }
            .right-one-container {
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin-left: 5px;
                border: 1px solid #2F517E;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                .right-arrow {
                    width: 6px;
                    height: 6px;
                    border-top: 1px solid rgba(227, 240, 255, 1);
                    border-left: 1px solid rgba(227, 240, 255, 1);
                    transform: rotate(135deg)
                }
            }
        }
        .user-portrait-detail {
            box-sizing: border-box;
            height: calc(100% - 30px);
            padding: 10px 40px;
            .detail-item {
                display: inline-block;
                width: 33%;
                height: 20px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #E3F0FF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
</style>